








import config from "@/config";
import {Qiniu} from "../common/QiniuJsSDK";
import E from 'wangeditor'
import {mapActions} from "vuex";

export default {
    name: "el-editor",
    model: {
        prop: 'val',
        event: 'change'
    },
    props: {
        val: {
            type: String
        },
        width: {
            type: String,
            default: '500px'
        },
        height: {
            type: String,
            default: '400px'
        },
        excludeMenu: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            percent: 0,
            token: '',
            editor: Object,
            menu: [
                'head',  // 标题
                'bold',  // 粗体
                'fontSize',  // 字号
                'fontName',  // 字体
                'italic',  // 斜体
                'underline',  // 下划线
                'strikeThrough',  // 删除线
                'foreColor',  // 文字颜色
                'backColor',  // 背景颜色
                'link',  // 插入链接
                'list',  // 列表
                'justify',  // 对齐方式
                'quote',  // 引用
                'emoticon',  // 表情
                'image',  // 插入图片
                'table',  // 表格
                'video',  // 插入视频
                'code',  // 插入代码
                'undo',  // 撤销
                'redo'  // 重复
            ],
            myInterval: undefined
        }
    },
    mounted() {
        let self: any = this;
        self.editor = new E(self.$refs.toolbar, self.$refs.editor);
        self.editor.customConfig.onchange = (html: any) => {
            self.$emit('change', html)
        };

        self.editor.customConfig.menus = self.menu.concat(self.excludeMenu).filter((j: string) => self.menu.includes(j) && !self.excludeMenu.includes(j));

        // 使用 base64 保存图片
        // self.editor.customConfig.uploadImgShowBase64 = true;

        // 隐藏“网络图片”tab
        self.editor.customConfig.showLinkImg = false;

        // 允许上传到七牛云存储
        self.editor.customConfig.qiniu = true;

        self.editor.create();
        self.uploadInit();
    },
    destroyed() {
        let self: any = this;
        clearInterval(self.myInterval);
    },
    methods: {
        ...mapActions([
            'qiniuToken'
        ]),
        setHtml(html: any) {
            let self: any = this;
            self.editor.txt.html(html);
        },
        uploadInit() {
            let self: any = this;
            // 获取相关 DOM 节点的 ID
            let btnId = self.editor.imgMenuId;
            let containerId = self.editor.toolbarElemId;
            let textElemId = self.editor.textElemId;
            self.qiniuToken().then((token: any) => {
                self.token = token;
                let uploader = Qiniu.uploader({
                    runtimes: 'html5',    //上传模式,依次退化
                    browse_button: btnId,       //上传选择的点选按钮，**必需**
                    uptoken: token,
                    unique_names: true,
                    domain: config.cdn, //bucket 域名，下载资源时用到，**必需**
                    container: containerId,           //上传区域DOM ID，默认是browser_button的父元素，
                    max_file_size: '1mb',           //最大文件体积限制
                    filters: {
                        mime_types: [
                            //只允许上传图片文件 （注意，extensions中，逗号后面不要加空格）
                            {title: "图片文件", extensions: "jpg,jpeg,gif,png,bmp"}
                        ]
                    },
                    max_retries: 1, //上传失败最大重试次数
                    dragdrop: true, //开启可拖曳上传
                    drop_element: textElemId, //拖曳上传区域元素的ID，拖曳文件或文件夹后可触发上传
                    //chunk_size: '1mb', //分块上传时，每片的体积
                    auto_start: true,  //选择文件后自动上传，若关闭需要自己绑定事件触发上传
                    init: {
                        'BeforeUpload'(up: any, file: any) {
                            up.setOption({
                                multipart_params: {
                                    token: self.token
                                }
                            });
                        },
                        'FileUploaded'(up: any, file: any, info: any) {
                            console.log(up);
                            console.log(file);
                            console.log(info);
                            let domain = up.getOption('domain');
                            let res = JSON.parse(info);
                            let sourceLink = domain + res.key; //获取上传成功后的文件的Url
                            // 插入图片到editor
                            self.editor.cmd.do('insertHtml', '<img src="' + sourceLink + '" style="max-width:100%;height:auto;margin-bottom: -5px;"/>');
                        },
                        'UploadProgress'(up: any, file: any) {
                            // 显示进度
                            self.percent = file.percent;
                            // console.log(`${self.percent}%`)
                        },
                        'Error'(up: any, err: any, errTip: string) {
                            self.$Notice.warning({
                                duration: 0,
                                title: '上传失败',
                                desc: errTip
                            });
                        }
                    }
                });
                self.myInterval = setInterval(() => self.qiniuToken().then((uptoken: any) => self.token = uptoken), 5000);
            })
        }
    }
}
